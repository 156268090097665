/* Flip square */
$theme-color: (
  success: #00FF00, // Ejemplo de definición de color para el estado de éxito
  // Otros colores de tema aquí
);
  .flip-square-loader{
    -webkit-perspective: 120px;
    -moz-perspective: 120px;
    -ms-perspective: 120px;
    perspective: 120px;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    position: relative;
    margin: 0 auto;
  }
  
  .flip-square-loader:before{
    content: "";
    position: absolute;
    left: 25px;
    top: 25px;
    width: 50px;
    height: 50px;
    background-color: rgba(map-get($theme-color, success), 0.8);
    animation: flip 1s infinite;
  }
  
  @keyframes flip {
    0% {
      transform: rotate(0);
    }
  
    50% {
      transform: rotateY(180deg);
    }
  
    100% {
      transform: rotateY(180deg)  rotateX(180deg);
    }
  }