/* Tables */

.table {
  margin-bottom: 0;
  padding: 10px;
  thead {
    th {
      border-top: 0;
      border-bottom-width: 1px;
      font-weight: 800;
      font-size: 1.1rem;
      i {
        margin-left: 0.325rem;
      }
    }
  }
  th,
  td {
    vertical-align: middle;
    font-size: $default-font-size;
    line-height: 1;
    white-space: nowrap;
    img {
      width: 36px;
      height: 36px;
      border-radius: 100%;
    }
    .badge {
      margin-bottom: 0;
    }
  }
  &.table-borderless {
    border: none;
    tr,
    td,
    th {
      border: none;
    }
  }
  .active {
    font-weight: 800;
    color: #046d53;
  }

  .inactive {
    font-weight: 800;
    color: #f22c3d;
  }
}
