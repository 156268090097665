.footer {
  background: $footer-bg;
  color: $footer-color;
  padding: 49px 1rem;
  transition: all $action-transition-duration $action-transition-timing-function;
  -moz-transition: all $action-transition-duration $action-transition-timing-function;
  -webkit-transition: all $action-transition-duration $action-transition-timing-function;
  -ms-transition: all $action-transition-duration $action-transition-timing-function;
  font-size: calc(#{$default-font-size} - 0.05rem);
  font-weight: $font-weight-regular;
  a {
    color: $text-muted;
    font-size: inherit;
  }
  @media (max-width: 991px) {
    margin-left: 0;
    width: 100%;
  }
}

.logo-down {
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: white;
  padding: 10px 15px;
  border-radius: 50px;
  font-weight: bold;
}
