/* Pagination */
// En algún archivo de tu código Sass, puedes definir el mixin así:
@mixin transition-duration($duration) {
  -webkit-transition-duration: $duration;
  -moz-transition-duration: $duration;
  -ms-transition-duration: $duration;
  -o-transition-duration: $duration;
  transition-duration: $duration;
}
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}
.pagination {
  .page-item {
    .page-link {
      border-color: $border-color;
      color: color(black);
      font-size: .875rem;
      @include transition-duration(0.3s);
      &:focus{
        background: inherit;
      }
      i {
        &:before {
          font-size: inherit;
          line-height: 1;
          vertical-align: middle;
        }
      }
    }
    &.active,
    &:hover,
    &:focus,
    &:active {
      .page-link {
        background: theme-color("primary");
        border-color: theme-color("primary");
        color: color(white);
      }
    }
  }
  &.flat {
    .page-item {
      .page-link {
        border: none;
        @include border-radius(2px);
      }
    }
  }
  &.separated {
    .page-item {
      margin-left: 2px;
      margin-right: 2px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      .page-link {
        @include border-radius(2px);
      }
    }
  }
  &.rounded {
    .page-item {
      .page-link {}
      &:first-child {
        .page-link {
          @include border-radius(25px 0 0 25px);
        }
      }
      &:last-child {
        .page-link {
          @include border-radius(0 25px 25px 0);
        }
      }
    }
  }
  &.rounded-flat{
    .page-item {
      margin-right: 3px;
      margin-left: 3px;
      .page-link{
        border: none;
        @include border-radius(50px);

      }
    }
  }
  &.rounded-separated{
    .page-item {
      margin-left: 2px;
      margin-right: 2px;
      .page-link{
        @include border-radius(18px);
      }
    }
  }
}


/* pagination variations */
@each $color, $value in $theme-colors {
  .pagination-#{$color} {
    @include pagination-variants($value);
  }
}