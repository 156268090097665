body {

  font-family: $type1-bold;
  background-color: #ffffff00 !important;
  // background-image: url("../../assets/images/auth/register-bg.jpg")!important;
  min-height: 100vh;

  margin-top: 10rem;
}




.brand-wrapper {
  padding-top: 7px;
  padding-bottom: 8px;

  .logo {
    height: 25px;
  }
}

.login {
  &-section-wrapper {
    box-shadow: 0px 4px 6px #d9931175!important;
    display: flex;
    flex-direction: column;
    padding: 50px 120px;
    background-color: #f9f9f9;

    @media (max-width: 991px) {
      padding-left: 50px;
      padding-right: 50px;
    }
    @media (max-width: 575px) {
      padding-top: 20px;
      padding-bottom: 20px;
      min-height: 100vh;
      min-width: 57vh;
    }
  }

  &-wrapper {
    width: 59rem;
    max-width: 100%;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 24rem;

    @media (max-width: 1200) {
      width: 100%;
      padding-left: 10rem;
    }

    label {
      font-size: 18px;
      font-weight: bold;
      color: #b0adad;
    }

    .form-control {
      border: none;
      border-bottom: 1px solid #e7e7e7;
      border-radius: 0;
      padding: 9px 5px;
      min-height: 40px;
      font-size: 18px;
      font-weight: normal;

      &::placeholder {
        color: #b0adad;
      }
    }

    .login-btn {
      padding: 13px 20px;
      background-color: $sidebar-light-menu-icon-color;
      border-radius: 1rem;
      font-size: 20px;
      font-weight: bold;
      color: #fff;
      margin-bottom: 14px;
      margin-top: 20px;

      &:hover {
        border: 1px solid $sidebar-light-menu-icon-color;
        background-color: #fff;
        color: $sidebar-light-menu-icon-color;
      }
    }

    a.forgot-password-link {
      color: #080808;
      font-size: 14px;
      text-decoration: underline;
      display: inline-block;
      margin-bottom: 54px;

      @media (max-width: 575px) {
        margin-bottom: 16px;
      }
    }

    &-footer-text {
      font-size: 16px;
      color: #000;
      margin-bottom: 0;
    }
  }

  &-title {
    font-size: 34px;
    color: #000;
    font-weight: bold;
    margin-bottom: 25px;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
  }


  &-img {
    width: 89%;
    height: 100vh;
    object-fit: cover;
    object-position: left;
    padding: 14rem;
    @media (max-width: 1080) {
      &-img {
        width: 137%;
        height: 100vh;
        object-fit: cover;
        object-position: left;
        padding: 14rem;
      }
    }
  }
}
