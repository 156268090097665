/* Reset Styles */

body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    scrollbar-width: none;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    background-color:#f9f9f9  !important;
}

.form-control,


.form-control {
    box-shadow: none
}

.form-control:focus {
    outline: 0;
    box-shadow: none
}

a,
div,
h1,
h2,
h3,
h4,
h5,
p,
span {
    text-shadow: none
}

[type=button]:focus,
a:active,
a:focus,
a:visited,
button::-moz-focus-inner,
input[type=reset]::-moz-focus-inner,
input[type=button]::-moz-focus-inner,
input[type=submit]::-moz-focus-inner,
input[type=file]>input[type=button]::-moz-focus-inner,
select::-moz-focus-inner {
    outline: 0
}

input,
.form-control:focus,
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
    outline-width: 0;
    outline-color: transparent;
    box-shadow: none;
    outline-style: none;
}

textarea {
    resize: none;
    overflow-x: hidden;
}

.btn,
.btn-group.open .dropdown-toggle,
.btn:active,
.btn:focus,
.btn:hover,
.btn:visited,
a,
a:active,
a:checked,
a:focus,
a:hover,
a:visited,
body,
button,
button:active,
button:hover,
button:visited,
div,
input,
input:active,
input:focus,
input:hover,
input:visited,
select,
select:active,
select:focus,
select:visited,
textarea,
textarea:active,
textarea:focus,
textarea:hover,
textarea:visited {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus,
button,
button:active,
button:checked,
button:focus,
button:hover,
button:visited {
    outline: 0;
    outline-offset: 0
}

.bootstrap-select .dropdown-toggle:focus {
    outline: 0;
    outline-offset: 0
}

.dropdown-menu>li>a:active,
.dropdown-menu>li>a:focus,
.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:visited {
    outline: 0
}

a:focus,
input:focus {
    border-color: transparent;
    outline: none
}
.signature-pad{
border: 1px solid #000;
width: 100%;
min-height: 700px;
border-radius: 20px;
}

.custom-select-container {
    position: relative;
  }
  
  .custom-select {
    border: 1px solid #ced4da;
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
    background-color: #fff;
    cursor: pointer;
  }
  
  .custom-dropdown {
    position: absolute;
    width: 100%;
    border: 1px solid #ced4da;
    background-color: #fff;
    z-index: 1000;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .dropdown-item {
    padding: 0.375rem 0.75rem;
    cursor: pointer;
  }
  
  .dropdown-item:hover {
    background-color: #f8f9fa;
  }
  .loader-container {
    display: flex;
    justify-content: center;
    height: 100vh;
}

.loader {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: linear-gradient(0deg, #FFFFFF 50%, #0f0f70 100%);
  animation: spin 1s linear infinite;
}

.inner-circle {
  width: 90%;
  height: 90%;
  background-color: white;
  border-radius: 50%;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to{
    transform: rotate(360deg);
  }
  
}
.custom-file-input {
  display: none !important;
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
}

.custom-radio .form-check-input {
  width: 1.2em;
  height: 1.2em;
  border-color: #000;
  border-width: 4px; /* Ajusta el grosor del borde */
}
.rounded-table {
  border-radius: 10px; /* Ajusta el valor según tus necesidades */
  overflow: hidden; /* Esto asegura que los bordes redondeados se apliquen correctamente */
}

.yarl__button .yarl__navigation_next{
  display: none;
}
