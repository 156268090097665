$typerequest-colors: (
  1: #FF5733, // Rojo
  2: #33FF57, // Verde
  3: #3357FF, // Azul
  4: #F39C12, // Naranja
  5: #9B59B6, // Morado
  6: #1ABC9C, // Turquesa
  7: #34495E  // Gris oscuro
);

@each $key, $color in $typerequest-colors {
  .typerequest-#{$key} {
    color: $color;
  }
}
// Convertir `typerequest` a `number`Color Functions Mixins

// Solid tab variations
@mixin tab-solid-variant($color) {
  .nav-link {
    &.active {
      background: $color;
    }
  }
}
// Minimal tab variations
@mixin tab-minimal-variant($color) {
  .nav-tabs {
    .nav-item {
      .nav-link {
        &.active {
          color: $color;
        }
      }
    }
  }
}
// Sider color variations
@mixin slider-color-variant($color) {
  .noUi-base {
    .noUi-connect {
      background: $color;
    }

    .noUi-origin {
      background: $color;

      .noUi-handle {
        &:after {
          background: $color;
        }

        .noUi-tooltip {
          background: $color;
          color: $white;
        }
      }
    }
  }
}
// Breadcrumb color variations
@mixin breadcrumb-inverse-variant($color) {
  background: lighten($color, 25%);
  border-color: $color;

  .breadcrumb-item {
    color: $color;

    &:before {
      color: inherit;
    }

    a {
      color: inherit;
    }
  }

  &.breadcrumb-custom {
    background: transparent;

    .breadcrumb-item {
      background: lighten($color, 25%);

      a {
        color: $color;
        border: none;

        &:before {
          border-left-color: lighten($color, 25%);
        }
      }

      span {
        color: $color;
      }

      &:last-child {
        background: transparent;
      }
    }
  }
}
// Pagination variations
@mixin pagination-variants($color) {
  .page-item {
    &.active {
      .page-link {
        background: $color;
        border-color: $color;
      }
    }

    .page-link {
      &:hover {
        background: lighten($color,5%);
        border-color: $color;
        color: $white;
      }
    }
  }
}
// Accordion Inverse variations
@mixin accordion-inverse-variant($color) {
  .card {
    background: rgba($color, 0.16);
    color: $color;
    border: none;

    .card-header {
      color: $color;
      border: none;

      a,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      span {
        color: $color;
      }

      a {
        border-color: $color;

        &:before {
          color: $color;
        }

        &[aria-expanded="true"] {
          border-bottom: 1px solid rgba($color, 0.16);
        }

        &[aria-expanded="false"] {
          border-bottom: 1px solid transparent;
        }
      }
    }
  }
}
