/* Buttons */

.btn {
  font-size: $btn-font-size;
  line-height: 1;
  font-weight: $font-weight-semibold;
  margin-right: 3px;
  i {
    font-size: 1rem;
  }
  &.btn-rounded {
    border-radius:50px;
  }
  &.btn-fw {
    min-width: $button-fixed-width;
  }
  &.btn-sm {
    font-size: $btn-font-size-sm;
  }
  &.btn-lg {
    font-size: $btn-font-size-lg;
  }
  &.btn-xs {
    padding: $btn-padding-y-xs $btn-padding-x-xs;
    font-size: $btn-font-size-xs;
  }
  /* Buttons with only icons */
  &.btn-icon {
    width: 42px;
    height: 42px;
    padding: 0;
  }
  /* Buttons with icon and text */
  &.btn-icon-text {
    .btn-icon-prepend {
      margin-right: .5rem;
    }
    .btn-icon-append {
      margin-left: .5rem;
    }
  }
  &.btn-social-icon {
    width: 50px;
    height: 50px;
    padding: 0;
  }
}
@media (min-width: 576px) {
  .btn-group {
    .btn {
      margin-right: 0;
      + .btn {
        border-left: 0;
      }
    }
  }
}

@media (max-width: 575px) {
  .btn-group {
    flex-direction: column;

    > .btn {
      border-radius: 0;
      margin-right: 0;
      margin-left: 0;

      &:first-child:not(.dropdown-toggle) {
        border-top-left-radius: 0.1875rem;
        border-top-right-radius: 0.1875rem;
      }

      &:last-child:not(.dropdown-toggle) {
        border-bottom-left-radius: 0.1875rem;
        border-bottom-right-radius: 0.1875rem;
      }

      &:not(first-child):not(.last-child) {
        border-radius: 0;
      }

      + .btn {
        border-top: 0;
        margin-left: 0;
      }
    }
  }
}

.btn-toolbar {
  .btn-group {
    +.btn-group {
      margin-left: 2;
    }
  }
}
/*social buttons*/
@each $color, $value in $social-colors {
  .btn-#{$color} {
    @include social-button(social-color($color));
  }
  .btn-outline-#{$color} {
    @include social-outline-button(social-color($color));
  }
}
/* inverse buttons */
@each $color, $value in $theme-colors {
  .btn-inverse-#{$color} {
    @include button-inverse-variant($value);
  }
  .btn-#{$color}:not(.btn-light) {
    color: $white;
    &:hover,
    &:focus,
    &:active {
      color: $white;
    }
    &:focus,
    &:active {
      background: $value;
      border-color: $value;
    }
  }
  .btn-outline-#{$color} {
    &:hover,
    &:focus,
    &:active {
      background: theme-color($color);
      color: $white;
    }
  }
}